<template>
    <div class="empty-page"></div>
</template>
<script>
import { getLoginUser } from '@/service/api/userApi';
import { getEventList } from '@/service/api/menuApi';
export default {
  name: 'AutoLogin',

  data () {
    return {
      email: this.$route.query.email,
      aToken: this.$route.query.accessToken,
      rToken: this.$route.query.refreshToken,
      url: this.$route.query.redirectUrl,
      from: this.$route.query.from,
      eventId: this.$route.query.eventId
    };
  },

  created () {
    this.setCookie();
    this.autoLogin();
  },

  mounted () {},

  methods: {
    setCookie () {
      const comeFrom = this.$cookies.get('comeFrom');
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
      // comeFrom값 다시 셋팅
      this.$cookies.set('comeFrom', comeFrom);
      sessionStorage.removeItem('carCurationSpace');
      this.$store.commit('initData');
      if (this.$route.query.accessToken != null) {
        // 토큰값 있을 경우
        this.$cookies.set('aToken', this.$route.query.accessToken, '30d');
        this.$cookies.set('rToken', this.$route.query.refreshToken, '30d');
        this.$cookies.set('email', this.$route.query.email, '30d');

        this.$store.commit('setEmail', this.$route.query.email);
      }
    },
    autoLogin () {
      if (this.$route.query.accessToken != null) {
        // 토큰값 있을 경우
        const email = this.$cookies.get('email');
        getLoginUser(email).then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            const userId = result.userId;
            const email = result.email;
            const comeFrom = result.comeFrom;
            const userType = result.userType;
            const signupCouponAt = result.signupCouponAt;
            const division = result.division;

            // 쿠키 셋팅
            this.$cookies.set('userId', userId);
            this.$cookies.set('email', email);
            this.$cookies.set('comeFrom', comeFrom);
            this.$cookies.set('userType', userType);
            // store 셋팅
            this.$store.commit('setEmail', email);
            this.$store.commit('setSignupCouponAt', signupCouponAt);
            this.$store.commit('setDivision', division);
            this.$store.commit('setRoute', result.route ? result.route : null);

            if (this.$route.query.redirectUrl === 'EventView') {
              // event list 조회
              // localStorege 세팅
              getEventList(100, 0).then(res => {
                const { result, resultCd } = res.data;
                if (resultCd === '0000') {
                  const { eventList } = result;
                  const curDate = new Date();
                  const januaryEvent = new Date('2024-01-01 00:00:00');
                  if (curDate.getTime() >= januaryEvent.getTime()) {
                    if (this.from === 'mobile') this.$store.commit('setFromMobile', false);
                    const eventId = this.$route.query.eventId ? this.$route.query.eventId : 'event_000025';
                    this.$store.commit('setEventId', eventId);
                    this.$router.push({ name: 'EventView' });
                  } else {
                    const find = eventList.find(item => item.eventTitle.includes('12월 출석체크 이벤트'));
                    if (find) {
                      if (this.from === 'mobile') this.$store.commit('setFromMobile', false);
                      this.$store.commit('setEventId', 'event_000024');
                      this.$router.push({ name: 'EventView' });
                    } else {
                      this.$router.push({ name: 'Event' });
                    }
                  }
                }
              });
            } else if (this.$route.query.redirectUrl === 'Coupon') {
              // 최종 결제 후 앱 Open 여부
              this.$store.commit('setIsAppOpen', true);
              this.$router.push({ name: this.$route.query.redirectUrl });
            } else {
              this.$router.push({ name: this.$route.query.redirectUrl });
            }
          }
        });
      }
    }
  }
};
</script>
